.mobile-notifications-wrapper {
  width: 100vw;
  z-index: 3;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100vh;
}
.mobile-notifications-blur-mask {
  width: 100%;
  height: 100%;
  background: var(--black-40);
  transition: ease opacity .3s;
  opacity: 0;
  position: absolute;
  top: 0;
  z-index: -1;
}
.mobile-notifications-wrapper.show { 
  overflow-y: hidden;
  animation: ShowWrapper .6s forwards;
}
.mobile-notifications-wrapper.hide {
  animation: HideWrapper .6s 0.6s forwards;
  overflow-y: hidden;
}
.mobile-notifications-wrapper.none {
  display: none;
}
.mobile-notifications-blur-mask.show {
  animation: ShowBlur .6s forwards;
}
.mobile-notifications-blur-mask.hide {
  animation: HideBlur .6s forwards;
}
.mobile-notifications-blur-area {
  height: 30vh;
  width: 100%;
  z-index: 2;
}
.mobile-notifications-container {
  width: 100vw;
  height: 70vh;
  position: relative;
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
  background: var(--primary-blue-default);
  display: flex;
  flex-direction: column;
  padding: 0 16px 16px;
  gap: 32px;
}
.mobile-notifications-container.show {
  animation: MoveUp .6s forwards;
}
.mobile-notifications-container.hide {
  animation: MoveDown .6s forwards;
}
  .mobile-notifications-top-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
}
.mobile-notifications-white-bar {
  position: absolute;
  top: 4px;
  width: 80px;
  height: 3px;
  border-radius: 4px;
  background: var(--white);
}
.mobile-notifications-title {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  color: var(--white);
  margin-top: 24px;
}
.mobile-notifications-clean-button-container {
  background: var(--neutral-600);
  border-radius: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 145px;
  height: 40px;
  position: relative;
  padding-left: 14px;
}
.mobile-notifications-clean-button-content {
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: var(--neutral-300);
}
.mobile-notifications-cross-bar-left {
  position: absolute;
  width: 12px;
  height: 1px;
  left: 23px;
  transform: rotate(45deg);
  background: var(--neutral-300);
}
.mobile-notifications-cross-bar-right {
  position: absolute;
  width: 12px;
  height: 1px;
  left: 23px;
  transform: rotate(-45deg);
  background: var(--neutral-300);
}
.mobile-notifications-list-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  gap: 16px;
}
.mobile-notifications-list-container::-webkit-scrollbar {
  background-color: transparent;
}
.mobile-notifications-card-container {
  display: flex;
  width: 100%;
  height: 68px;
  border-radius: 8px;
  background: var(--white);
  padding: 8px 16px;
  gap: 8px;
}
.mobile-notifications-card-icon {
  max-width: 16px;
  max-height: 16px;
  min-width: 16px;
  min-height: 16px;
  border-radius: 50%;
  background-color: red;
  margin-top: 4px;
}
.mobile-notifications-card-content-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: 8px;
}
.mobile-notifications-card-title {
  font-family: 'Lato';
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: var(--primary-blue-default);
}
.mobile-notifications-card-content {
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: var(--neutral-400);
}

@keyframes MoveDown {
  from {
    transform: translateY(0);
  } to {
    transform: translateY(100vh);
  }
}

@keyframes MoveUp {
  from {
    transform: translateY(100vh);
  } to {
    transform: translateY(0);
  }
}