.mobile-authorization-card-container {
  display: flex;
  width: 100%;
  height: 86px;
  background: var(--white);
  box-shadow: 2px 2px 20px rgba(63, 61, 77, 0.1);
  border-radius: 8px;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
}
.mobile-authorization-card-left-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.mobile-authorization-card-title {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  letter-spacing: 0.5px;
  color: var(--neutral-200);
}
.mobile-authorization-card-subtitle {
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: var(--neutral-400);
}
.mobile-authorization-card-status {
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
  border-radius: 50%;
  background: var(--secondary-yellow-default);
}
.mobile-authorization-card-status-Concluída {
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
  border-radius: 50%;
  background: var(--primary-blue-default);
}
.mobile-authorization-card-status-Agendadas {
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
  border-radius: 50%;
  background: var(--green-default);
}
.mobile-authorization-card-status-Autorizada {
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
  border-radius: 50%;
  background: var(--red-default);
}
.mobile-authorization-card-status-Pendentes {
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
  border-radius: 50%;
  background: var(--secondary-yellow-default);
}
.mobile-authorization-card-status-Reportado {
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
  border-radius: 50%;
  background: var(--red-default);
}