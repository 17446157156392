.mobile-profile-wrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  position: absolute;
  top: 0;
  left: calc(-100vw - 10px);
  z-index: 3;
  flex-direction: column;
  padding: 40px 20px 0;
  background: var(--white);
  display: flex;
  overflow: auto !important;
}
.mobile-profile-wrapper.hide {
  animation: Exit .3s forwards;
}
.mobile-profile-wrapper.show {
  animation: Enter .3s forwards;
}
.mobile-profile-top-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  justify-content: center;
}
.mobile-profile-title {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  letter-spacing: -0.408px;
  color: var(--neutral-100);
}
.mobile-profile-user-image-container {
  position: relative;
  background: var(--neutral-600);
  border-radius: 50%;
  width: 158px;
  height: 158px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mobile-profile-empty-user-image {
  margin-top: 22px;
}
.mobile-profile-user-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-image: cover;
  object-fit: cover;
  object-position: center;
}
.mobile-profile-photograph {
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: var(--primary-blue-default);
  border: 6px solid var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  right: -30px;
  top: calc(50% - 32px);
}
.mobile-profile-form-container {
  height: 100%;
  width: 100%;
  gap: 42px;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}
.mobile-profile-input-container {
  width: 100%;
  gap: 8px;
  display: flex;
  flex-direction: column;
  position: relative;
}
.mobile-profile-label {
  font-family: 'Lato';
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: var(--neutral-200);
}
.mobile-profile-input {
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: var(--neutral-200);
  height: 54px;
  width: 100%;
  background: var(--white);
  border: 1px solid var(--neutral-100);
  border-radius: 16px;
  padding: 8px 16px;
}
.mobile-profile-input-password {
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: var(--neutral-200);
  height: 54px;
  width: 100%;
  background: var(--white);
  border: 1px solid var(--neutral-100);
  border-radius: 16px;
  padding: 8px 16px;
  padding-right: 40px;
}
.mobile-profile-input-password::placeholder {
  font-size: 60px;
  letter-spacing: -4px;
  transform: translate(-2px, 12px);
}
.mobile-profile-input-row {
  display: flex;
  width: 100%;
  gap: 16px;
}
.mobile-profile-button-row {
  width: 100%;
  padding: 0 69px;
  padding-left: 72px;
  display: flex;
  align-items: center;
  margin-bottom: 27px;
  gap: 0 33px;
}
.mobile-profile-button-back {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: -0.408px;
  border-bottom: 1px solid var(--neutral-300);
  color: var(--neutral-300);
  line-height: 18px;
}
.mobile-profile-button-save {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  letter-spacing: -0.408px;
  color: var(--white);
  background: var(--primary-blue-default);
  border-radius: 56px !important;
  width: 100%;
  height: 48px;
}
.show-pass-icon {
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 54px;
  padding: 14px;
  display: none;
  height: 54px;
  border-bottom-right-radius: 16px;
  border-top-right-radius: 16px;
}
.show-pass-icon.show {
  display: unset;
  color: var(--primary-blue-default);
}

@keyframes Enter {
  from {
    left: calc(-100vw - 10px);
  } to {
    left: 0;
  }
}
@keyframes Exit {
  from {
    left: 0;
  } to {
    left: calc(-100vw - 10px);
  }
}
@media not all and (min-resolution:.001dpcm) { 
  .mobile-profile-input-password::placeholder {
    font-size: 60px;
    letter-spacing: -4px;
    transform: translate(-2px, -4px);
  }
}