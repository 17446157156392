.div-card-calendario {
  /* width: 291.07px; */
  /* height: 311.58px; */
  background: var(--white);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 32px;
}
.div-teste {
  height: 125.69px;
  background: var(--green-light);
}
.search-calendario {
  height: 48px;
  background: var(--white);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 66px;
}
.border-calendario {
  border-left: 18px solid;
  background-color: var(--green-light);
  height: 150px;
  padding: 10px;
}
.border-Agendado {
  border-color: var(--green-default);
}
.border-Agendado-equipe {
  border-color: var(--yellow-default);
}
.hora-cirurgia {
  color: var(--primary-blue-default);
}
.data-calendario-style {
  /* position: absolute; */
  right: 4rem;
  cursor: default;
  color: var(--primary-blue-default);
}
.text-grey {
  color: var(--neutral-400);
}
.state-cirurgia {
  width: 16px;
  height: 16px;
  border-radius: 100%;
}
.Autorizada {
  background: #eb5757;
}
.Pendentes {
  background: var(--yellow-default);
}

@media (max-width: 768px) {
  nav.nav-desktop {
      /* display: none; */
  }
}
@media (min-width: 800px) and (max-width: 850px) {
  nav.nav-desktop {
      /* display: none; */
  }
}
