.mobile-new-sellers-wrapper {
  width: 100vw;
  z-index: 3;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100vh;
}
.mobile-new-sellers-blur-mask {
  width: 100%;
  height: 100%;
  background: var(--black-40);
  transition: ease opacity .3s;
  opacity: 0;
  position: absolute;
  top: 0;
  z-index: -1;
}
.mobile-new-sellers-wrapper.show { 
  overflow-y: hidden;
  animation: ShowWrapper .6s forwards;
}
.mobile-new-sellers-wrapper.hide {
  animation: HideWrapper .6s 0.6s forwards;
  overflow-y: hidden;
}
.mobile-new-sellers-wrapper.none {
  display: none;
}
.mobile-new-sellers-blur-mask.show {
  animation: ShowBlur .6s forwards;
}
.mobile-new-sellers-blur-mask.hide {
  animation: HideBlur .6s forwards;
}
.mobile-new-sellers-blur-area {
  height: 30vh;
  width: 100%;
  z-index: 2;
}
.mobile-new-sellers-container {
  width: 100vw;
  height: 70vh;
  position: relative;
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
  background: var(--primary-blue-default);
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  gap: 32px;
}
.mobile-new-sellers-container.show {
  animation: MoveUp .6s forwards;
}
.mobile-new-sellers-container.hide {
  animation: MoveDown .6s forwards;
}
  .mobile-new-sellers-top-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
}
.mobile-new-sellers-white-bar {
  position: absolute;
  top: 4px;
  width: 80px;
  height: 3px;
  border-radius: 4px;
  background: var(--white);
}
.mobile-new-sellers-title {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  color: var(--white);
  margin-top: 24px;
}
.mobile-new-sellers-clean-button-container {
  background: var(--neutral-600);
  border-radius: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 145px;
  height: 40px;
  position: relative;
  padding-left: 14px;
}
.mobile-new-sellers-clean-button-content {
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: var(--neutral-300);
}
.mobile-new-sellers-cross-bar-left {
  position: absolute;
  width: 12px;
  height: 1px;
  left: 23px;
  transform: rotate(45deg);
  background: var(--neutral-300);
}
.mobile-new-sellers-cross-bar-right {
  position: absolute;
  width: 12px;
  height: 1px;
  left: 23px;
  transform: rotate(-45deg);
  background: var(--neutral-300);
}
.mobile-new-sellers-form-container {
  display: flex;
  flex-direction: column;
  width: calc(100% + 16px);
  height: 100%;
  min-height: min-content;
  overflow: auto;
  gap: 42px;
  padding-bottom: 16px;
}
.mobile-new-sellers-form-container::-webkit-scrollbar {
  background-color: transparent;
}
.mobile-new-sellers-input-container {
  width: calc(100% - 16px);
  gap: 8px;
  display: flex;
  flex-direction: column;
  position: relative;
}
.mobile-new-sellers-label {
  font-family: 'Lato';
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: var(--white);
}
.mobile-new-sellers-input {
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: var(--neutral-200);
  height: 54px;
  width: 100%;
  background: var(--white);
  border: 1px solid var(--neutral-100);
  border-radius: 16px;
  padding: 8px 16px;
}
.mobile-new-sellers-button {
  background: var(--secondary-yellow-default);
  box-shadow: 2px 2px 20px rgba(63, 61, 77, 0.1);
  border-radius: 56px !important;
  width: calc(100% - 16px);
  height: 72px !important;
  min-height: 72px;
  color: var(--white);
  position: relative;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  letter-spacing: 0.5px;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
}
.right-arrow {
  position: absolute;
  right: 0;
  height: 20px;
}
@keyframes ShowWrapper {
  from {
    display: none;
  } to {
    display: unset;
    z-index: 3;
  }
}

@keyframes HideWrapper {
  from {
    z-index: 1;
  } to {
    z-index: -1;
  }
}

@keyframes ShowBlur {
  from {
    opacity: 0;
  } to {
    opacity: 1;
  }
}

@keyframes HideBlur {
  from {
    opacity: 1;
  } to {
    opacity: 0;
  }
}

@keyframes MoveDown {
  from {
    transform: translateY(0);
  } to {
    transform: translateY(100vh);
  }
}

@keyframes MoveUp {
  from {
    transform: translateY(100vh);
  } to {
    transform: translateY(0);
  }
}