input.input-vendedores {
  border-radius: 16px !important;
  position: relative !important;
}
.input-vendedores {
  position: relative !important;
}
input.input-vendedores.error {
  border: 2px solid red;
  background: rgba(255, 192, 192, 0.15);
}
.label.file-btn-foto {
  transition: 0.2s;
}
div.btn-save-vendedor {
  transition: 0.2s ease-out;
}
div.btn-save-vendedor:hover {
  transform: scale(1.01);
  transition: 0.2s ease-in;
}
label.file-btn-foto:hover {
  transform: rotate(5deg);
  transition: 0.2s ease-in;
}
img.img-profile-modal {
  width: 150px;
  height: 150px;
}
