.mobile-authorizations-wrapper{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 32px 20px 0;
  gap: 24px;
}
.mobile-authorizations-top-container{
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.mobile-authorizations-title{
  font-family: 'Lato';
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 0.5px;
  color: var(--neutral-200);
}
.mobile-authorizations-search-container{
  width: 100%;
  height: 64px;
  background: var(--white);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 56px !important;
  box-shadow: 0px 2px 15px rgba(63, 61, 77, 0.1);
  padding-right: 4px;
}
.mobile-authorizations-search-input {
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  width: calc(100% - 72px);
  outline: none !important;
  transform: none !important;
  border: none !important;
}
.mobile-authorizations-search-icon {
  padding: 20px 16px 25px 24px;
  height: 70px;
  width: 70px;
}
.mobile-authorizations-search-input::placeholder {
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  color: var(--neutral-500);
}
.mobile-authorizations-list-container{
  width: calc(100% + 16px);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: min-content;
  padding: 16px 0;
  gap: 16px;
}
.mobile-authorizations-list-container::-webkit-scrollbar{
  background-color: transparent;
}
.mobile-authorizations-list-empty {
  margin: auto;
  padding-right: 8px;
  margin-top: 48px;
}
.mobile-authorizations-list-empty{
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: var(--neutral-100);
}
.mobile-authorization-modal-wrapper {
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  min-height: min-content;
  z-index: 3;
  position: absolute;
  top: 0;
  padding-top: 120px;
}
.mobile-authorization-modal-wrapper.show {
  overflow: hidden;
  animation: ShowWrapper .4s forwards;
}
.mobile-authorization-modal-wrapper.hide {
  animation: HideWrapper .4s 0.6s forwards;
  overflow: hidden;
}
.mobile-authorization-modal-wrapper.none {
  display: none;
}
.mobile-authorization-modal-blur-mask {
  width: 100%;
  height: 100%;
  position: absolute;
  background: var(--black-40);
  top: 0;
}
.mobile-authorization-modal-blur-mask.show {
  animation: ShowBlur .4s forwards;
  overflow: hidden;
}
.mobile-authorization-modal-blur-mask.hide {
  animation: HideBlur .4s forwards;
  overflow: hidden;
}
.mobile-authorization-modal-container {
  height: min-content;
  width: 100vw;
  display: flex;
  flex-direction: column;
  gap: 40px;
  position: absolute;
  left: 0;
  background: var(--white);
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
}
.mobile-authorization-modal-container.show {
  animation: MoveUp .6s forwards;
}
.mobile-authorization-modal-container.hide {
  animation: MoveDown .6s forwards;
}