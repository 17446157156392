div .modalBuscarMedico {
  width: 500px;
  height: 550px;
  overflow-y: scroll;
  overflow-x: hidden;
}

::placeholder {
  font-size: 18px;
}

h3 {
  color: rgb(39, 40, 72)
}

.buttonPendente {
  background-color: rgb(239, 117, 23);
}

.buttonEmEquipe {
  background-color: rgb(179, 179, 179);
}