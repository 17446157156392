.mobile-authorization-modal-subcontainer {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 120px);
  overflow-y: auto;
  width: 100%;
  padding: 80px 25px 0px 16px;
  margin-top: 16px;
}
.mobile-authorization-modal-top-bar {
  width: 80px;
  height: 4px;
  border-radius: 2px;
  background: var(--secondary-yellow-default);
  position: absolute;
  top: 8px;
  left: calc(50% - 40px);
}
.mobile-authorization-modal-top-bar.Agendadas {
  background: var(--green-default);
}
.mobile-authorization-modal-top-bar.Autorizada {
  background: var(--red-default);
}
.mobile-authorization-modal-top-bar.Concluída {
  background: var(--primary-blue-default);
}
.mobile-authorization-modal-top-bar.Reportado {
  background: var(--red-default);
}
.mobile-authorization-modal-top-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  position: fixed;
  left: 0;
  height: 80px;
  padding-left: 16px;
  top: 0;
  padding-top: 8px;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  padding-bottom: 16px;
  background: var(--white);
}
.mobile-authorization-modal-status {
  width: 104px;
  height: 38px;
  border-radius: 56px;
  background: var(--secondary-yellow-light-20);
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Lato';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: -0.408px;
  color: var(--secondary-yellow-default);
}
.mobile-authorization-modal-status.Agendadas {
  background: var(--green-default-20);
  color: var(--green-default);
}
.mobile-authorization-modal-status.Autorizada {
  background: var(--red-default-20);
  color: var(--red-default);
}
.mobile-authorization-modal-status.Concluída {
  background: var(--primary-blue-default-20);
  color: var(--primary-blue-default);
}
.mobile-authorization-modal-status.Reportado {
  background: var(--red-default-20);
  color: var(--red-default);
}
.mobile-authorization-modal-cross-container {
  width: 18px;
  height: 18px;
  position: absolute;
  right: 33px;
}
.mobile-authorization-modal-cross-bar-left {
  width: 22px;
  height: 3px;
  border-radius: 2px;
  background: var(--neutral-100);
  transform: rotate(45deg);
  top: 50%;
  position: absolute;
}
.mobile-authorization-modal-cross-bar-right {
  width: 22px;
  height: 3px;
  border-radius: 2px;
  background: var(--neutral-100);
  top: 50%;
  transform: rotate(-45deg);
  position: absolute;
}
.mobile-authorization-modal-center-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: 16px;
}
.mobile-authorization-modal-doctor {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  letter-spacing: -0.408px;
  color: var(--neutral-100);
}
.mobile-authorization-modal-info-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.mobile-authorization-modal-info-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
}
.mobile-authorization-modal-info-wrapper .mobile-authorization-modal-info-container {
  margin-top: 0 !important;
}
.mobile-authorization-modal-info-container:first-child {
  margin-top: 8px;
}
.mobile-authorization-modal-info {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  letter-spacing: -0.408px;
  color: var(--neutral-200);
}
.mobile-authorization-modal-info-content {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  letter-spacing: -0.408px;
  color: var(--neutral--300);
}
.mobile-authorization-modal-attachments-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}
.mobile-authorization-modal-attachments-title {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  letter-spacing: -0.408px;
  color: var(--primary-blue-default);
}
.mobile-authorization-modal-attachments-info-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}
.mobile-authorization-modal-attachments-info {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  letter-spacing: -0.408px;
  color: var(--neutral-200);
}
.mobile-authorization-modal-attachments-info-content {
  display: flex;
  gap: 16px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: -0.408px;
  color: var(--primary-blue-default);
}
.mobile-authorization-modal-materials-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.mobile-authorization-modal-materials-title {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  letter-spacing: -0.408px;
  color: var(--primary-blue-default);
}
.mobile-authorization-modal-materials-content {
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: -0.408px;
  color: var(--neutral-300);
}
.mobile-authorization-modal-report-info {
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  letter-spacing: -0.408px;
  color: var(--neutral-400);
}
.mobile-authorization-modal-button-row {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 48px;
  padding-bottom: 32px;
}
.mobile-authorization-modal-button-row.Agendadas {
  display: none;
}
.mobile-authorization-modal-button-row.Autorizada {
  display: none;
}
.mobile-authorization-modal-button-row.Concluída {
  display: none;
}
.mobile-authorization-modal-button-report {
  border: 1px solid var(--red-default);
  border-radius: 64px !important;
  display: flex;
  align-items: center;
  width: 118px;
  height: 48px;
  justify-content: center;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 19px;
  color: var(--red-default);
}
.mobile-authorization-modal-button-confirm {
  background: var(--secondary-yellow-default);
  width: 118px;
  height: 48px;
  border-radius: 64px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 19px;
  color: var(--white);
}
.mobile-authorization-modal-button-confirm.Autorizada {
  background: var(--red-default);
}
.mobile-authorization-modal-button-confirm.Agendadas {
  background: var(--green-default);
}
.mobile-agendadas-edit-button {
  width: 118px;
  height: 48px;
  border-radius: 64px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 19px;
  color: var(--white);
  background: var(--primary-blue-default);
  margin: 0 auto;
  margin-top: 16px;
}
.mobile-authorization-modal-button-confirm.Concluída {
  background: var(--primary-blue-default);
}
.mobile-exclude-guide-wrapper {
  background: var(--black-40);
  z-index: 2;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: -120px;
  display: flex;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mobile-exclude-guide-wrapper.none {
  display: none;
}
.mobile-exclude-guide-blank-area {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  top: 0;
}
.mobile-exclude-guide-container {
  background: var(--white);
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  padding: 32px 28px 24px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 352px;
  height: min-content;
  z-index: 3;
}
.mobile-exclude-guide-title {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 19px;
  color: var(--primary-blue-default);
}
.mobile-exclude-guide-button-row {
  display: flex;
  width: 100%;
  height: 42px;
  justify-content: space-between;
  gap: 24px;
}
.mobile-exclude-guide-cancel-button {
  border: 2px solid var(--primary-blue-default);
  border-radius: 64px;
  width: 100%;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: var(--primary-blue-default);
  padding: 10px 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mobile-exclude-guide-exclude-button {
  background: var(--primary-blue-default);
  border-radius: 64px;
  width: 100%;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 22px;
  color: var(--white);
  padding: 10px 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mobile-authorization-submodal-wrapper {
  width: 100vw;
  height: 120vh;
  left: 0;
  position: absolute;
  bottom: 0;
}
.mobile-authorization-submodal-wrapper.show {
  overflow: hidden;
  animation: ShowWrapper .4s forwards;
}
.mobile-authorization-submodal-wrapper.hide {
  animation: HideWrapper .4s 0.6s forwards;
  overflow: hidden;
}
.mobile-authorization-submodal-wrapper.none {
  display: none;
}
.mobile-authorization-submodal-blur-mask {
  width: 100%;
  height: 100%;
  position: absolute;
  background: var(--black-40);
}
.mobile-authorization-submodal-blur-mask.show {
  animation: ShowBlur .4s forwards;
  overflow: hidden;
}
.mobile-authorization-submodal-blur-mask.hide {
  animation: HideBlur .4s forwards;
  overflow: hidden;
}
.mobile-authorization-submodal-container {
  height: min-content;
  min-height: 80vh;
  max-height: 88vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  gap: 40px;
  position: absolute;
  padding: 48px 16px;
  left: 0;
  bottom: 0;
  background: var(--white);
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
}
.mobile-authorization-submodal-container.show {
  animation: MoveUp .6s forwards;
}
.mobile-authorization-submodal-container.hide {
  animation: MoveDown .6s forwards;
}
.mobile-authorization-top-bar {
  width: 80px;
  height: 4px;
  border-radius: 4px;
  background: var(--secondary-yellow-default);
  position: absolute;
  top: 8px;
  left: calc(50% - 40px);
}
.mobile-authorization-submodal-list-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.mobile-authorization-submodal-title {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  letter-spacing: -0.408px;
  color: var(--neutral-100);
  text-align: center;
}
.mobile-authorization-submodal-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.mobile-authorization-submodal-textarea-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.mobile-authorization-submodal-textarea-title {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  letter-spacing: -0.408px;
  color: var(--neutral-200);
}
.mobile-authorization-submodal-textarea {
  padding: 16px;
  border: 1px solid var(--neutral-100) !important;
  transform: none !important;
  opacity: 1 !important;
  border-radius: 16px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  letter-spacing: -0.408px;
  color: var(--neutral-300);
  outline: none !important;
  width: 100%;
  height: 185px;
  max-height: 220px !important;
}
.mobile-authorization-submodal-button-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}
.mobile-authorization-submodal-button-send {
  background: var(--primary-blue-default);
  border-radius: 16px !important;
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 22px;
  color: var(--white);
}
.mobile-authorization-submodal-button-back {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--neutral-100);
  width: 100%;
  border-radius: 16px !important;
  height: 64px;
}
.mobile-authorization-submodal-button-back span {
  line-height: 24px;
  border-bottom: 1px solid var(--neutral-100);
}
.mobile-checklist-wrapper {
  position: absolute;
  top: -120px;
  width: 100vw;
  height: 100vh;
  padding: 12px;
  padding-top: 0;
  display: flex;
  justify-content: center;
  opacity: 0;
  display: none;
  transition: opacity ease .3s;
}
.mobile-checklist-wrapper.show {
  opacity: 1;
  z-index: 1;
  display: flex;
}
.mobile-checklist-blur-mask {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  background-color: var(--black-40);
}
.mobile-checklist-container {
  width: 100%;
  height: min-content;
  background: var(--white);
  padding: 32px 30px;
  position: relative;
  margin-top: 64px;
  border-radius: 32px;
}
.mobile-checklist-cross-container {
  position: absolute;
  right: 17px;
  top: 24px;
  width: 29px;
  padding: 15px 5px;
  height: 29px;
  border-radius: 50%;
  cursor: pointer;
}
.mobile-checklist-cross-bar-left {
  width: 20px;
  height: 2px;
  border-radius: 2px;
  transform: rotate(45deg);
  background: var(--primary-blue-default);
  margin-top: -1px;
}
.mobile-checklist-cross-bar-right {
  width: 20px;
  height: 2px;
  border-radius: 2px;
  transform: rotate(-45deg);
  background: var(--primary-blue-default);
  margin-top: -2px;
}
.mobile-checklist-percent-info {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: var(--primary-blue-default) !important;
}
.mobile-post-modal-percent-info {
  color: var(--primary-blue-default);
}
.mobile-checklist-title {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  color: var(--primary-blue-default);
  text-align: center;
}
.mobile-checklist-exclude-button {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 40px;
  background: var(--primary-blue-default);
  border: 1.5px solid var(--primary-blue-default);
  box-sizing: border-box;
  border-radius: 24px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 19px;
  color: var(--white);
  margin-top: 20px;
  margin-left: auto;
}
@keyframes MoveDown {
  from {
    transform: translateY(0);
  } to {
    transform: translateY(100vh);
  }
}

@keyframes MoveUp {
  from {
    transform: translateY(100vh);
  } to {
    transform: translateY(0);
  }
}