.mobile-calendar-wrapper {
  padding: 32px 20px 0 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 28px;
}
.mobile-calendar-top-container {
  display: flex;
  width: 100%;
  gap: 16px;
  justify-content: center;
  align-items: center;
}
.mobile-calendar-top-calendar-icon {
  width: 22px;
  height: 22px;
}
.mobile-calendar-top-arrow-container {
  position: relative;
  width: 16px;
}
.mobile-calendar-top-arrow-bar-left {
  position: absolute;
  transform: rotate(45deg);
  width: 10px;
  height: 3px;
  border-radius: 2px;
  background: var(--neutral-100);
  left: 0;
  top: -2px;
}
.mobile-calendar-top-arrow-bar-right {
  position: absolute;
  right: 0;
  transform: rotate(-45deg);
  width: 10px;
  height: 3px;
  border-radius: 2px;
  background: var(--neutral-100);
  top: -2px;
}
.moible-calendar-top-content {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 22px;
  color: var(--neutral-100);
}
.mobile-calendar-title-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.mobile-calendar-title {
  font-family: 'Lato';
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: var(--primary-blue-default);
}
.mobile-calendar-search-container{
  width: 100%;
  height: 64px;
  background: var(--white);
  display: flex;
  align-items: center;
  border-radius: 56px !important;
  box-shadow: 0px 2px 15px rgba(63, 61, 77, 0.1);
}
.mobile-calendar-search-input {
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  width: calc(100% - 72px);
  outline: none !important;
  transform: none !important;
  border: none !important;
}
.mobile-calendar-search-icon {
  padding: 20px 16px 25px 24px;
  height: 70px;
  width: 70px;
}
.mobile-calendar-search-input::placeholder {
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  color: var(--neutral-500);
}
.mobile-calendar-card-list {
  display: flex;
  flex-direction: column;
  min-height: min-content;
  overflow-y: auto;
  max-height: calc(100% + 8px);
  width: calc(100% + 8px);
  gap: 16px;
}
.mobile-calendar-card-list::-webkit-scrollbar {
  background-color: transparent;
}
.div-card-calendario {
  /* background: var(--white);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 32px; */
}
.DayPicker-wrapper {
  background: var(--white);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 32px;
}
.mobile-calendar-modal-wrapper {
  width: 100vw;
  height: 100vh;
  z-index: 3;
  left: 0;
  position: absolute;
  top: 0;
}
.mobile-calendar-modal-wrapper.show {
  overflow: hidden;
  animation: ShowWrapper .4s forwards;
}
.mobile-calendar-modal-wrapper.hide {
  animation: HideWrapper .4s 0.6s forwards;
  overflow: hidden;
}
.mobile-calendar-modal-wrapper.none {
  display: none;
}
.mobile-calendar-modal-blur-mask {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -10 !important;
  background: var(--black-40);
}
.mobile-calendar-modal-blur-mask.show {
  animation: ShowBlur .4s forwards;
  overflow: hidden;
}
.mobile-calendar-modal-blur-mask.hide {
  animation: HideBlur .4s forwards;
  overflow: hidden;
}
.mobile-calendar-modal-container {
  height: min-content;
  width: 374px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  position: absolute;
  left: calc(50% - 175px);
  top: calc(50% - 162.5px);
  /* box-shadow: 2px 2px 20px rgba(63, 61, 77, 0.1); */
  border-radius: 24px;
}
.mobile-calendar-modal-container.show {
  animation: MoveLeft .6s forwards;
}
.mobile-calendar-modal-container.hide {
  animation: MoveRight .6s forwards;
}