.toast-style {
  border-radius: 5px;
}
.toast-error {
  background-color: #f14444;
}
.toast-success {
  background-color: var(--green-default);
}
.btn-dropdown:hover .dropdown-content {
  display: block;
}
div.scroll-box {
  height: 302px;
  background-color: transparent;
}

div.scroll-box::-webkit-scrollbar {
  background-color: red;
  width: 0px;
}

div.scroll-box:hover::-webkit-scrollbar {
  opacity: 1;
  width: 3px;
}

div.scroll-box::-webkit-scrollbar-track {
  background-color: #fff;
  width: 5px;
}

div.scroll-box::-webkit-scrollbar-thumb {
  background-color: var(--neutral-500);
  width: 3px;
  border-radius: 52px;
}
.DayPicker-wrapper,
.DayPicker-Day {
  outline: none !important;
  transition: 0.1s ease-in !important;
  font-size: 0.95rem !important;
  cursor: pointer !important;
}
.DayPicker {
  width: 350px !important;
  height: 100% !important;
}
.DayPicker-Day:focus-within {
  border-bottom: 3px solid var(--green-default) !important;
  color: var(--green-default) !important;
  font-weight: bold !important;
  transition: 0.1s ease-in !important;
}
span.DayPicker-NavButton--prev {
  margin-right: 1.5em;
  background-image: url("../../../../src/assets/prev.png") !important;
}

span.DayPicker-NavButton--next {
  background-image: url("../../../../src/assets/next.png") !important;
}
span.DayPicker-NavButton--prev,
span.DayPicker-NavButton--next {
  outline: none !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
}
div.DayPicker-WeekdaysRow {
  font-weight: bold !important;
  color: #b5b5b5 !important;
}

@media (max-width: 832px) {
  .img-main-container {
    height: 100vh;
    width: 100vw;
    padding-top: 68%;
  }
  .bg-img-mobile-container {
    position: absolute;
    top: 0;
  }
  .wrapper {
    display: flex;
    width: 100%;
    margin-top: 0px !important;
    height: 100%;
  }
  .container {
    padding: 0;
    margin: 0;
    background: var(--primary-blue-default);
    min-height: 350px;
    height: 100%;
    width: 100%;
    max-width: unset !important;
    border-top-right-radius: 32px;
    border-top-left-radius: 32px;
    border-bottom-left-radius: unset !important;
    border-bottom-right-radius: unset !important;
  }
  .form-body {
    height: unset !important;
    width: unset !important;
  }
  .intro-layout-child-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 !important;
    padding: 0 !important;
  }
  .bg-img-mobile {
    width: 100%;
    height: 100%;
    margin-top: -30px;
  }
  .bg-img-health {
    position: absolute;
    left: calc(22.5%);
    top: calc(36.8% - 80px);
    width: 57px;
    height: 57px;
  }
  .bg-img-heart {
    position: absolute;
    right: calc(22.5%);
    top: calc(28.8% - 76px);
    width: 81px;
    height: 70px;
  }
  .logo-intro {
    width: 40%;
    left: calc(50% - 20%);
    top: calc(42%);
    position: absolute;
  }
  .logo-intro-illustration {
    width: 100%;
    position: absolute;
    top: 36px;
    right: 45px;
  }
  .form-type-logo-intro {
    top: calc(42% - 180px);
  }
  .form-bg-img-health {
    display: none;
  }
  .form-bg-img-heart {
    display: none;
  }
}

@media (max-width: 414px) {
  .img-main-container {
    height: 100vh;
    width: 100vw;
    max-width: 100vw;
    padding-top: 70%;
  }
  .img-main-container.patient {
    padding-top: 100% !important;
  }
  .bg-img-mobile-container {
    position: absolute;
    top: 0;
  }
  .wrapper {
    display: flex;
    width: 100%;
    height: 100%;
  }
  .container {
    padding: 0;
    margin: 0;
    background: var(--primary-blue-default);
    max-width: unset !important;
    min-height: 350px;
    height: 100%;
    width: 100%;
    border-top-right-radius: 32px;
    border-top-left-radius: 32px;
    border-bottom-left-radius: unset !important;
    border-bottom-right-radius: unset !important;
  }
  .form-body {
    height: unset !important;
    width: unset !important;
  }
  .intro-layout-child-container {
    height: 40%;
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .bg-img-mobile {
    width: 100%;
    height: 100%;
    margin-top: -110px;
  }
  .bg-img-health {
    position: absolute;
    left: calc(15%);
    top: calc(36.8% - 64px);
    width: 37px;
    height: 37px;
  }
  .bg-img-heart {
    position: absolute;
    right: calc(12%);
    top: calc(28.8% - 66px);
    width: 53px;
    height: 45px;
  }
  .logo-intro {
    width: 179px;
    left: calc(50% - 89.5px);
    top: calc(42%);
    position: absolute;
  }
  .logo-intro-illustration {
    width: 285px;
    position: absolute;
    top: 36px;
    right: 45px;
  }
  .form-type-logo-intro {
    top: 72px;
  }
  .form-type-logo-intro-illustration {
    display: none;
  }
}
