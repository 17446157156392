@media(max-width: 832px) {
  .div-select {
    width: 100%;
    height: 54px;
  }
  .react-select {
    height: 54px !important;
  }
  .login-button-container {
    padding-bottom: 0 !important;
  }
  .button {
    background: var(--secondary-yellow-default);
    margin-top: 8px;
  }
  .select-simple-select-container {
    padding: 0 20px;
    margin: 0;
    margin-top: 24px;
  }
  .css-yv70a0-control {
    height: 54px !important;
    min-height: 54px !important;
  }
  .css-1j38729-control {
    height: 54px !important;
    min-height: 54px !important;
  }
}
@media(max-width: 414px) {
  .login-button-container {
    padding-bottom: 0 !important;
  }
  .button {
    background: var(--secondary-yellow-default);
    margin-top: 8px;
  }
  .select-simple-select-container {
    padding: 0 20px;
    margin: 0;
    margin-top: 24px;
  }
}