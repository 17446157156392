.secretariado-title {
  color: #4f4f4f;
  font-size: 32px;
}

.secretariado-info-message {
  color: #4f4f4f;
}

/* .email-secretariado-input {
  
} */

.vincular-email-button {
  background-color: #e98015;
  color: white;
  width: 184px;
  height: 32px;
}

.vincular-email-button:hover {
  transform: scale(1.1);
}
