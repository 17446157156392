div.scroll-box {
  height: calc(100% - 2.75rem);
  background-color: transparent !important;
}

div.scroll-box::-webkit-scrollbar {
  background-color: red;
  width: 0px;
}

div.scroll-box:hover::-webkit-scrollbar {
  opacity: 1;
  width: 3px;
}

div.scroll-box::-webkit-scrollbar-track {
  background-color: #fff;
  width: 5px;
}

div.scroll-box::-webkit-scrollbar-thumb {
  background-color: var(--neutral-500);
  width: 3px;
  border-radius: 52px;
}

.Agendadas {
  background: var(--green-default);
}
.Autorizada {
  background: #eb5757;
}
.Pendentes {
  background: var(--yellow-default);
}

/* Mobile */

.mobile-home-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  overflow-y: auto;
}
.mobile-home-surgery-container {
  height: min-content;
  width: 100%;
  min-height: 212px;
  display: flex;
  flex-direction: column;
  padding: 0 21px;
  padding-right: 10px;
  margin-top: 32px;
  gap: 16px;
}
.mobile-home-surgery-content {
  font-family: 'Lato';
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 0.5px;
  color: var(--neutral-200);
}
.mobile-home-surgery-list {
  width: 100%;
  display: flex;
  gap: 0 16px;
  overflow-x: auto;
  height: 100%;
  padding-bottom: 16px;
}
.mobile-home-surgery-list::-webkit-scrollbar {
  background-color: transparent;
}
.mobile-home-surgery-calendar-button {
  min-width: 276px;
  height: 100%;
  background: var(--primary-blue-default);
  border-radius: 8px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  letter-spacing: 0.5px;
  color: var(--white);
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  position: relative;
}
.mobile-home-surgery-calendar-button-bg-calendar {
  position: absolute;
  top: -4px;
  right: -24px;
  width: 130px;
  height: 130px;
  opacity: 0.2;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(338deg) brightness(104%) contrast(101%);
}
.mobile-home-surgery-calendar-blur-part {
  position: absolute;
  right: -20px;
  width: 20px;
  height: 100%;
  background: var(--neutral-800);
}
.mobile-home-authorization-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 21px;
  padding-right: 37px;
}
.mobile-home-authorization-content {
  font-family: 'Lato';
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 0.5px;
  color: var(--neutral-200);
}
.mobile-home-authorization-list-container {
  width: calc(100% + 16px);
  max-height: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 0;
  gap: 16px;
  overflow-y: auto;
}
.mobile-home-authorization-list-container::-webkit-scrollbar {
  background-color: transparent;
}
.mobile-home-empty-content {
  font-family: 'Lato';
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0.5px;
  color: var(--neutral-300);
  text-align: center;
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
}
.mobile-home-authorization-link {
  background: var(--primary-blue-default);
  width: 100%;
  height: 72px;
  min-height: 72px;
  border-radius: 16px;
  box-shadow: 2px 2px 20px rgba(63, 61, 77, 0.1);
  color: var(--white);
  position: relative;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  letter-spacing: 0.5px;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
}
.right-arrow {
  position: absolute;
  right: 0;
  height: 20px;
}