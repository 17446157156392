.form-error-span {
  color: var(--red-default);
  font-size: 14px;
  font-weight: 700;
}
.form-error-span.absolute {
  position: absolute;
  bottom: -26px;
  left: 4px;
}
.form-error-span.none {
  display: none;
}
.modal-perfil.form-error-span.absolute {
  bottom: -20px;
  left: 0;
}