.modalConfirmacao {
  width: 390px;
  height: 219px;
  color: rgb(235, 87, 87)
}

.backgroundClass  {
  background-color: rgb(235, 87, 87);
}


.pendenteBackGroundClass {
  background-color: rgb(239, 117, 23)
}