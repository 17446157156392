/* .popup-content {
  margin: auto;
  background: transparent;
  width: 100% !important;
} */
.modal {
  animation: animate 0.2s ease-in-out;
}
.close-btn {
  right: -10px;
  top: -10px;
  position: absolute;
  transition: 0.2s;
}
.close-btn:hover {
  transform: scale(0.95);
  transition: 0.5s;
}
.popup-root:focus {
  border: none !important;
}
.content {
  border-radius: 32px;
  width: 100%;
}
.text-blue {
  color: var(--primary-blue-default) !important;
}
.border-blue {
  border-color: var(--primary-blue-default);
}
div.btn-agendadas {
  transition: 0.2s;
}
div.btn-agendadas:hover {
  transition: 0.2s;
  transform: scale(1.02);
}
.get-nome,
.get-span,
.file-span {
  border: 2px solid transparent !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
}
.popup-arrow {
  color: rgb(255, 255, 255);
}
.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
  overflow-y: scroll;
  backdrop-filter: blur(3px);
  padding: 30px;
}
.popup-overlay::-webkit-scrollbar {
  display: none;
}
[data-popup="tooltip"].popup-overlay {
  background: transparent;
}
.post-modal {
  margin-left: -15px;
  border-radius: 50px 32px 32px 32px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
  margin-bottom: 50px !important;
}
.get-modal {
  border-radius: 32px 50px 32px 32px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
  height: 100%;
  margin-bottom: 50px !important;
}
.background-Autorizada {
  background: #f3d8d8;
}
.background-Pendentes {
  background: #fff7e1;
}
.background-Agendadas {
  background: #e1ffee;
}
.background-Reportado {
  background: #f3d8d8;
}
.background-Concluída {
  background: #ADE3FF;
}
.background-Solicitada {
  background: rgb(255, 222, 101)
}

.title-Solicitada {
  color: rgb(255, 136, 0)
}

.title-Autorizada {
  color: #eb5757;
}
.title-Pendentes {
  color: var(--yellow-default);
}
.title-Reportado {
  color: #eb5757;
}
.title-Agendadas {
  color: var(--green-default);
}
.title-Concluída {
  color: var(--primary-blue-default);
}
.active-accordion {
  height: 100%;
  display: block;
}
.accordion-content {
  height: 0px;
  display: none;
  animation: animate 0.2s ease-in-out;
}
h3.percent-info {
  color: var(--primary-blue-default);
}
@keyframes animate {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.post-modal-completed-container {
  display: flex;
  flex-direction: column;
  padding: 25px 0;
  padding-bottom: 0;
}
.post-modal-completed-title {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  color: var(--primary-blue-default);
  align-self: center;
}
.post-modal-completed-centerbox-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 40px;
  padding: 0 28px;
}
.post-modal-completed-centerbox-title {
  font-family: 'Lato';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: var(--neutral-100);
}
.post-modal-completed-textarea {
  padding: 7px 10px;
  border: 2px solid var(--black);
  border-radius: 8px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: var(--black);
  min-width: 252px;
  height: 100px;
  resize: none;
}
.post-modal-completed-textarea:disabled {
  background-color: unset;
  cursor: default;
}
.post-modal-completed-attachments-title {
  font-family: 'Lato';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: var(--neutral-100);
  padding: 0 28px;
  margin-top: 40px;
}
.post-modal-completed-attachments-anchor-container {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
  padding: 0 28px;
}
.post-modal-completed-attachments-anchor {
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #54C1FB;
}
.post-modal-completed-cross-container {
  position: relative;
  margin-top: 2px;
  width: 10px;
  height: 10px;
}
.post-modal-completed-cross-bar-left {
  position: absolute;
  width: 10px;
  height: 1px;
  border-radius: 2px;
  background-color: #EB5757;
  left: 0;
  transform: rotate(45deg);
  bottom: 4.5px;
  cursor: pointer;
}
.post-modal-completed-cross-bar-right {
  position: absolute;
  width: 10px;
  height: 1px;
  transform: rotate(-45deg);
  bottom: 4.5px;
  border-radius: 2px;
  background-color: #EB5757;
}