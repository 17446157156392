div .contentContainer {
  margin-left: 276px;
}


.mobile-home-layout-wrapper {
  background: var(--neutral-800);
  height: 100vh;
  width: 100vw;
}
.mobile-home-layout-container {
  height: 100%;
  width: 100%;
  position: relative;
}
.mobile-home-main-container {
  display: flex;
  flex-direction: column;
  height: calc(100% - 212px);
  width: 100%;
}