.mobile-auxiliaries-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 32px 20px;
}
.mobile-auxiliaries-wrapper.fixed {
  overflow: hidden;
}
.mobile-auxiliaries-sellers-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 16px;
  height: 100%;
}
.mobile-auxiliaries-sellers-top-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: min-content;
}
.mobile-auxiliaries-sellers-top-title {
  font-family: 'Lato';
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: var(--neutral-100);
}
.mobile-auxiliaries-sellers-new-button {
  width: 112px;
  height: 32px;
  border-radius: 64px;
  background: var(--primary-blue-default);
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 19px;
  color: var(--white);
}
.mobile-auxiliaries-sellers-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: min-content;
  max-height: 100%;
  overflow-y: auto;
  gap: 16px;
}
.mobile-auxiliaries-sellers-empty-list {
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: var(--neutral-100);
}
.mobile-confirm-modal-wrapper {
  width: 100vw;
  height: 100vh;
  z-index: 3;
  position: absolute;
  top: 0;
}
.mobile-confirm-modal-wrapper.show {
  overflow: hidden;
  animation: ShowWrapper .4s forwards;
}
.mobile-confirm-modal-wrapper.hide {
  animation: HideWrapper .4s 0.6s forwards;
  overflow: hidden;
}
.mobile-confirm-modal-wrapper.none {
  display: none;
}
.mobile-confirm-modal-blur-mask {
  width: 100%;
  height: 100%;
  position: absolute;
  background: var(--black-40);
}
.mobile-confirm-modal-blur-mask.show {
  animation: ShowBlur .4s forwards;
  overflow: hidden;
}
.mobile-confirm-modal-blur-mask.hide {
  animation: HideBlur .4s forwards;
  overflow: hidden;
}
.mobile-confirm-modal-container {
  height: 216px;
  width: 374px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  position: absolute;
  left: calc(50% - 187px);
  top: calc(50% - 108px);
  background: var(--white);
  box-shadow: 2px 2px 20px rgba(63, 61, 77, 0.1);
  border-radius: 24px;
}
.mobile-confirm-modal-container.show {
  animation: MoveLeft .6s forwards;
}
.mobile-confirm-modal-container.hide {
  animation: MoveRight .6s forwards;
}
.mobile-confirm-modal-title {
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 29px;
  color: var(--neutral-200);
  padding: 32px 18px 0 14px;
}
.mobile-confirm-modal-row-button {
  display: flex;
  justify-content: space-between;
  padding: 0 47px;
}
.mobile-confirm-modal-button-cancel {
  background: var(--primary-blue-default);
  width: 118px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 64px !important;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 19px;
  color: var(--white);
}
.mobile-confirm-modal-button-confirm {
  width: 118px;
  height: 48px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--red-default);
  border: 2px solid var(--red-default);
  border-radius: 64px !important;
}

@keyframes MoveLeft {
  from {
    transform: translateX(100vw);
  } to {
    transform: translateX(0);
  }
}

@keyframes MoveRight {
  from {
    transform: translateX(0);
  } to {
    transform: translateX(100vw);
  }
}