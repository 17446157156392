.autocomplete-container {
  position: relative;
}

.autocomplete-options {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  overflow-y: auto;
}

.autocomplete-option {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.autocomplete-option:hover,
.autocomplete-option.selected {
  background-color: #f0f0f0;
}

table {
  border-collapse: collapse;
  width: 100%;
}

/* table, th, td {
  border: 1px solid black;
} */

th,
td {
  padding: 3px;
  text-align: left;
}



.TypeAheadDropDown {
  position: relative;
  /* display: inline-block; */
  width: 100%;
  /* border: 1px solid gray; */
  /* box-shadow: 0 0 1px rgba(0,0,0,0.1), 0 2px 4px 1px rgba(0,0,0, .18); */
  /* font-family: Arial, Helvetica, sans-serif; */
  /* font-size: 14px; */
  color: rgba(0, 0, 0, 0.73);
}

.TypeAheadDropDown input {
  /* width: 100%; */
  padding: 10px 5px;
  box-sizing: border-box;
  outline: none;
}

.TypeAheadDropDown ul::before {
  content: '';
}

.TypeAheadDropDown ul {
  position: absolute;
  top: 65px;
  list-style-type: none;
  text-align: left;
  margin: 0;
  padding: 0;
  z-index: 1000;
  height: auto;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid gray;
  left: 0;
  background-color: white;
}

.TypeAheadDropDown li {
  cursor: pointer;
  /* position: absolute; */
  overflow-y: hidden;
  z-index: 1000;
  padding: 8px;
}

.TypeAheadDropDown li:hover {
  background: lightgray;
}

.backdrop-blur {
  backdrop-filter: blur(10px);
  background: lightgray;
  opacity: 97%;
}

/* .lock-icon {
  transition: transform 0.3s ease-in-out, color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.lock-icon:hover {
  transform: scale(1.1);
  color: rgb(255, 165, 0);
} */
