.mobile-calendar-card-wrapper {
  background: var(--green-light);
  box-shadow: 0px 2px 15px rgba(63, 61, 77, 0.1);
  border-radius: 8px;
  width: 100%;
  padding: 8px;
  overflow-y: hidden;
  min-height: 112px;
  height: 112px;
}
.mobile-calendar-card-top-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.mobile-calendar-card-title-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.mobile-calendar-card-title {
  font-family: 'Lato';
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0.5px;
  color: var(--neutral-100);
}
.mobile-calendar-card-time {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  letter-spacing: 0.5px;
  color: var(--primary-blue-default);
}
.mobile-calendar-card-subtitle {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.5px;
  color: var(--neutral-3001);
}
.mobile-calendar-card-center-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
}
.mobile-calendar-card-content {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.5px;
  color: var(--neutral-400);
}