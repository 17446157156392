.mobile-floating-nav-container {
  display: flex;
  width: 100%;
  height: 80px;
  background: var(--white);
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
  position: fixed;
  z-index: 1;
  align-items: center;
  bottom: 0;
  gap: 40px;
  padding: 0 32px !important;
}
.mobile-floating-nav-container.disabled {
  display: none;
}
  .mobile-floating-nav-option {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 7px 0;
  padding: 0 10px;
}
.mobile-floating-nav-option.active {
  background: var(--neutral-800);
  transition: border-top 0s !important;
  border-top: 2px solid var(--primary-blue-default);
}
.mobile-floating-nav-option-content {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: var(--neutral-200);
}
@media (min-width: 414px) {
  .mobile-floating-nav-container {
    padding: 0 20%;
  }
}