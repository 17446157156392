.toggleClass {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  /* height: 6vh; */
  background-color: #e6e8ea;
  border: solid 1px #E6E8EA;
  border-radius: 30px;
  width: 46px;
  height: 24px;
}

.toggle-div {
  background-color: #2460D7;
}

.toggle-button {
  width: 20px;
  height: 20px;
  border-radius: 30px;
  background-color: rgb(254, 254, 254);
  border-color: #E6E8EA;
  position: relative;
  transition: all 0.3s ease;
  cursor: pointer;
  /* box-shadow: 0 0 5px #E6E8EA; */
}

.toggle-button.active {
  /* background-color: #2460D7; */
  transform: translateX(23px)
}