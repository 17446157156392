.divNavButtons {
  top: 150px;
  left: 330px;
}

.divAddParticipante {
  top: 200px;
  left: 305px;
  padding-top: 10px;
}
.divPendentes {
  top: 200px;
  left: 305px;
  padding-top: 10px;
}

.titleClass {
  /* line-height: 32x; */
  /* letter-spacing: 1px; */
  margin-left: px;
  font-weight: bold;
  color: rgb(79,79,79);
  padding-left: 20px;
}

.msgClass {
  color: rgb(79,79,79);
  font-size: bold;
  margin-left: 58px;
  margin-top: 15px;
}

#nav-button {
  color: rgb(179, 179, 179)
}

.nav-link {
  text-decoration: none;
  color: rgb(179, 179,179);
  padding: 5px;
}

.equipesClass {
  /* line-height: 32x; */
  /* letter-spacing: 1px; */
  margin-left: 5px;
  font-weight: bold;
  font-size: 24px;
  color: rgb(79,79,79);
  padding-left: 20px;
}

.nomesClass {
  color: rgb(79,79,79);
  font-size: 20px;
}

.pinIcon {
  color: rgb(36, 96, 215);
  font-size: 20px;
}